import utils from './utils';

let origTextArray: string[] = [];

export default {
    init(section: HTMLElement) {
        const sm = utils.getMQValue('sm');

        if (!section) return;

        // remove old origin text from previous page
        if (origTextArray.length) {
            origTextArray = [];
        }

        const elements = section.querySelectorAll(
            '.split-headline'
        ) as NodeListOf<HTMLElement>;

        if (!elements) return;

        elements.forEach((element) => {
            splitTextIntoLines(element);
        });

        window.addEventListener('resize', () => {
            if (utils.checkIfMediaQueryChanged()) {
                resizeSplitTextIntoLines(elements);
            } else if (
                window.matchMedia('screen and (max-width: ' + sm + 'px)')
                    .matches
            ) {
                resizeSplitTextIntoLines(elements);
            }
        });

        /**
         * Saves each word of the div in a span. Then compares the position of all divs.
         * divs with the same positions (distance to parent element) are saved in new line span.
         */
        function splitTextIntoLines(element: HTMLElement) {
            const originalText = element.textContent;

            if (!originalText) return;

            const elementWords = originalText.split(' ');
            const divs: string[] = [];

            element.textContent = ' ';
            origTextArray.push(originalText); // save the original text in array so we can access it later

            elementWords.forEach((word) => {
                const newDiv = document.createElement('div');
                newDiv.classList.add('inline');
                newDiv.textContent = word + ' '; // add space at the end of a word

                element.appendChild(newDiv);

                // Create an Index value, which stores the current element's distance from the top of the parent
                // elements with the same Index value notify us that they are on the same line of text
                // offsetTop = the number of pixels from the top of the closest relatively positioned parent element
                // scrollTop = gets the number of pixels that an element's content is scrolled vertically.
                const index = newDiv.offsetTop - newDiv.scrollTop;

                // if the divs index value is equal to 'undefined' make it an empty value instead
                if (divs[index] === undefined) {
                    divs[index] = '';
                }

                // use the Index value to decide which divs to merge together (ones with the same value get merged)
                divs[index] += newDiv.textContent;

                // At this point each seperate word is in it's own span with a trailing whitespace
                // Clear the original elements text content
                element.textContent = '';

                // now re-populate the element with the newly merged divs
                divs.forEach((div) => {
                    if (div !== undefined) {
                        const divLine = document.createElement('div');
                        divLine.classList.add('split-item');
                        divLine.classList.add('inline');

                        const divP = document.createElement('span');
                        divP.classList.add('inline');
                        // add the text content and send the newly merged divs into the div
                        divP.textContent = div;
                        divLine.appendChild(divP);
                        element.appendChild(divLine);
                    }
                });
            });
        }

        /**
         * Set back original text and split elements again
         *
         */
        function resizeSplitTextIntoLines(elements: NodeListOf<HTMLElement>) {
            for (let i = 0; i < elements.length; i++) {
                const el = elements[i];

                // empty the element
                el.innerHTML = '';
                // send in the original text stored in the array
                const orig = origTextArray[i];
                el.textContent = orig;

                splitTextIntoLines(el);
            }
        }
    }
};
