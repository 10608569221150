import anime from 'animejs';

export default {
    init(section: HTMLElement) {
        // Elements
        const textRows =
            (section.querySelectorAll(
                '.split-item'
            ) as NodeListOf<HTMLElement>) || null;
        const subline =
            (section.querySelector('.text-hero') as HTMLElement) || null;
        const elementContainer = section.querySelector('.split-container');
        const formOverlayBtn = section.querySelector('.hero-button') || null;
        const imageDiv =
            section.querySelector('.image-div-hero-text-image') || null;

        if (elementContainer) {
            elementContainer.classList.remove('opacity-0');
        }

        if (textRows) {
            textRows.forEach((textRow) => {
                textRow.classList.add('set-block');
            });
        }

        // Start Animation
        const enterTimeline = anime.timeline();

        enterTimeline
            .add({
                targets: textRows,
                opacity: [0, 1],
                translateY: ['3rem', '0rem'],
                easing: 'easeOutSine',
                duration: 400,
                delay: anime.stagger(200, { start: 400 })
            })
            .add(
                {
                    targets: subline,
                    opacity: [0, 1],
                    translateX: ['3rem', '0rem'],
                    easing: 'easeOutQuint',
                    duration: 600
                },
                '-=100'
            )
            .add(
                {
                    targets: formOverlayBtn,
                    opacity: [0, 1],
                    translateY: ['100%', '0%'],
                    easing: 'easeOutQuint',
                    duration: 1000
                },
                '-=500'
            )
            .add(
                {
                    targets: imageDiv,
                    opacity: [0, 1],
                    translateY: ['100%', '0%'],
                    easing: 'easeOutQuint',
                    duration: 1000
                },
                '-=1000'
            );
    }
};
