import anime from 'animejs';
import ScrollMagic from 'scrollmagic';

export default {
    init(section: HTMLElement) {
        const controller = new ScrollMagic.Controller();
        const timeline = anime.timeline();
        const kpiScene = new ScrollMagic.Scene({
            triggerElement: section,
            triggerHook: 0.7
        }).addTo(controller);
        let animationPlayed = false;
        if (!kpiScene) return;

        const numbers = document.querySelectorAll(
            '.nbr'
        ) as NodeListOf<HTMLElement>;

        numbers.forEach((number: HTMLElement) => {
            const endNumber = parseInt(number.innerHTML);
            function animateValue(
                obj: HTMLElement | null,
                start: number,
                end: number,
                duration: number
            ) {
                let startTimestamp: number | null = null;
                const step = (timestamp: number | null) => {
                    if (!startTimestamp) startTimestamp = timestamp;
                    if (!timestamp || !startTimestamp || !obj) return;
                    const progress = Math.min(
                        (timestamp - startTimestamp) / duration,
                        1
                    );
                    obj.innerHTML = Math.floor(
                        progress * (end - start) + start
                    ).toString();
                    if (progress < 1) {
                        window.requestAnimationFrame(step);
                    }
                };
                window.requestAnimationFrame(step);
            }

            kpiScene.on('enter', function () {
                if (!animationPlayed) {
                    timeline.add({
                        begin: () => {
                            animationPlayed = true;
                        }
                    });
                    animateValue(number, 0, endNumber, 2000);
                }
            });
        });
    }
};
